import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


import api from '../../api/api';
import { setCategory } from '../../model/reducer/categoryReducer';
import { setFilterCategory } from '../../model/reducer/productFilterReducer';
import "./customMenu.css";


const CustomMainMenu = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const setting = useSelector((state) => state.setting);

  // const [selectedMainCategoryIndex, setSelectedMainCategoryIndex] = useState(-1);
  // const [selectedSubCategoryIndex, setSelectedSubCategoryIndex] = useState(0);
  const [selectedMainCategoryIndex, setSelectedMainCategoryIndex] = useState('');
  const [selectedSubCategoryIndex, setSelectedSubCategoryIndex] = useState('');
  const [menuData, setMenuData] = useState({
    "Loading": {
        "Loading": [
            {
                "id": 0,
                "row_order": 0,
                "name": null,
                "main_id": 0,
                "sub_id": 0,
                "sub_sub_id": 0,
                "main_name": "Loading",
                "sub_name": "Loading",
                "sub_sub_name": "Loading",
                "image_url": setting.setting && setting.setting.web_settings.web_logo,
                "has_child": false
            }
        ]
    }
});

  useEffect(() => {
    fetchCustomNavbar();
  }, []);

  // get position in percentage
  const getPosition = (key) => {
    const menuDataArray = Object.keys(menuData);
    let index = menuDataArray.indexOf(key);
    let align = (index < (menuDataArray.length / 2)) ? 'left' : 'right';
    const percentage = 100 / menuDataArray.length;
    index = (index < (menuDataArray.length / 2)) ? index : (menuDataArray.length - index - 1);
    const position = (percentage * index); 
    if(document.documentElement.dir === 'rtl') {
      align = (align === 'left') ? 'right' : 'left';
    }
    return { [align]: `${position}%` };
  }

  const fetchCategory = (id = 0) => {
    api.getCategory(id)
      .then(response => response.json())
      .then(result => {
          if (result.status === 1) {
              dispatch(setCategory({ data: result.data }));
              // dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
          }
      })
      .catch(error => console.log("error ", error));
  };

  const fetchCustomNavbar = async () => {
    try {
      await api.getCustomNavbar()
      .then(response => response.json())
      .then(async result => {
        if (result.status === 1) {
          setMenuData(result.data);
          console.log('data', result.data);
        }
      });
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  const selectCategory = (ctg) => {
    if (ctg.has_child) {
        fetchCategory(ctg.sub_sub_id);
        navigate('/products');
    } else {
        dispatch(setFilterCategory({ data: ctg.sub_sub_id }));
        navigate('/products');
    }
};

const handleSelectMainCategory = (data) => {
  setSelectedMainCategoryIndex(data);
  setSelectedSubCategoryIndex(menuData[data] ? Object.keys(menuData[data])[0] : '');
}

  return (
    <div
      className="mainContainer"
      onMouseLeave={() => {setSelectedMainCategoryIndex(''); setSelectedSubCategoryIndex('');}}
    >
      <div className="customNavbar">
        {Object.keys(menuData).map((data, index) => {
          return (
            <div
              key={index}
              className={`mainCategoryButton${selectedMainCategoryIndex === data ? ' activeCategory' : ''}`}
              onMouseOver={() => handleSelectMainCategory(data)}
            >
              {t(data)}
            </div>
          );
        })}
      </div>
      {
        selectedMainCategoryIndex !== '' &&
        <div className="subCategoryContainer" style={getPosition(selectedMainCategoryIndex)}>
          {
            Object.keys(menuData).length > 1 &&
            <div className="subCategoryList mt-2">
              {
                Object.keys(menuData[selectedMainCategoryIndex]).map((data, subIndex) => {
                  return (
                    <div key={subIndex} className={`subCategory${selectedSubCategoryIndex === data ? ' activeCategory' : ''}`} onClick={() => setSelectedSubCategoryIndex(data)}>
                      {t(data)}
                    </div>
                  );
                })
              }
            </div>
          }
          <div className="row mt-2 g-5">
            {
              selectedMainCategoryIndex !== '' && selectedSubCategoryIndex !== '' && menuData[selectedMainCategoryIndex][selectedSubCategoryIndex].map((data, subSubIndex) => {
                return (
                  <div key={subSubIndex} className='col-4 text-center subSubCategory' onClick={() => selectCategory(data)}>
                    <img className="subSubCategoryImg" src={menuData[selectedMainCategoryIndex][selectedSubCategoryIndex][subSubIndex].image_url} alt={menuData[selectedMainCategoryIndex][selectedSubCategoryIndex][subSubIndex].name} />
                    <div className="subSubCategoryTitle">{t(menuData[selectedMainCategoryIndex][selectedSubCategoryIndex][subSubIndex].sub_sub_name)}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  );
};

export default CustomMainMenu;
